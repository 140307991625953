<template>
    <div class="box">
        <div class="d-flex justify-content-center flex-column">
            <h4>Nombre de RD souhaité</h4>
            <div class="d-flex justify-content-center">
                <b-button class="mx-2" @click="setSpots(1)" :class="spots === 1 ? 'is-warning' : 'is-primary'"
                    >1
                </b-button>
                <b-button class="mx-2" @click="setSpots(5)" :class="spots === 5 ? 'is-warning' : 'is-primary'"
                    >5
                </b-button>
                <b-button class="mx-2" @click="setSpots(10)" :class="spots === 10 ? 'is-warning' : 'is-primary'"
                    >10
                </b-button>
                <b-button class="mx-2" @click="setSpots(20)" :class="spots === 20 ? 'is-warning' : 'is-primary'"
                    >20
                </b-button>
                <b-button
                    class="mx-2"
                    @click="
                        () => {
                            customSpots = !customSpots
                            spots = minCustomSpots
                        }
                    "
                    :class="customSpots ? 'is-warning' : 'is-primary'"
                    >Custom
                </b-button>
                <b-field>
                    <b-numberinput
                        v-if="customSpots"
                        controls-alignment="right"
                        controls-position="compact"
                        v-model="spots"
                    ></b-numberinput>
                </b-field>
            </div>
            <p v-if="rdTooLow" class="text-center has-text-danger pt-3">
                <b-icon icon="exclamation-circle" size="is-small"></b-icon>
                Merci de saisir un nombre de RD supérieur à {{ minCustomSpots }}
            </p>
            <p v-if="availableSpots && availableSpots < spots" class="text-center has-text-danger pt-3">
                <b-icon icon="exclamation-circle" size="is-small"></b-icon>
                Le nombre de RD souhaités dépasse le nombre de RD disponibles ({{ availableSpots }})
            </p>
        </div>
        <hr />
        <div>
            <h4>Informations de la commande</h4>
            <div class="p-3" style="position: relative">
                <b-field
                    :type="errorUrl ? 'is-danger' : ''"
                    :message="errorUrl ? errorUrl : ''"
                    label="URL ou Domaine, avec http(s)://"
                >
                    <b-loading :is-full-page="false" v-model="verifyUrlLoading"></b-loading>
                    <b-input expanded placeholder="URL" v-model="lowerUrl" @input="initTimer"></b-input>
                </b-field>
                <section>
                    <b-field style="position: relative">
                        <template #label>
                            Choix de la thématique
                            <b-tooltip
                                v-if="!urlIsValid"
                                type="is-dark"
                                label="Saisir une url pour choisir une thématique"
                            >
                                <b-icon size="is-small" icon="info-circle" type="is-danger"></b-icon>
                            </b-tooltip>
                        </template>
                        <b-loading :is-full-page="false" v-model="loadingCategories"></b-loading>
                        <b-select
                            v-model="selectedCategory"
                            placeholder="Choisir une thématique disponible"
                            :disabled="!urlIsValid"
                        >
                            <option v-for="group in groups" :key="group.id" :value="group">
                                {{ group.translation_name }} ({{ group.spots_count }})
                            </option>
                        </b-select>
                    </b-field>
                </section>
            </div>

            <h4>Ancre(s)</h4>
            <div class="p-3" style="position: relative">
                <b-field grouped group-multiline>
                    <div class="control">
                        <b-taglist attached>
                            <b-tag type="is-dark">Ancre(s) générique(s)</b-tag>
                            <b-tag type="is-warning">{{
                                spots - totalSpecificAnchor < 0 ? 0 : spots - totalSpecificAnchor
                            }}</b-tag>
                        </b-taglist>
                    </div>

                    <div class="control">
                        <b-taglist attached>
                            <b-tag type="is-dark">Ancre(s) spécifique(s)</b-tag>
                            <b-tag type="is-warning">{{ totalSpecificAnchor }}</b-tag>
                        </b-taglist>
                    </div>
                </b-field>
                <div v-for="(anchor, index) in specific_anchors" :key="index">
                    <div class="d-flex" style="gap: 1rem">
                        <b-field label="Nom de l'ancre">
                            <b-input placeholder="Ancre" v-model="anchor.anchor"></b-input>
                        </b-field>
                        <b-field label="Nombre de RD avec cette ancre">
                            <b-numberinput
                                controls-alignment="right"
                                controls-position="compact"
                                min="1"
                                v-model="anchor.rd_number"
                                expanded
                            ></b-numberinput>
                        </b-field>

                        <div class="d-flex align-items-center">
                            <b-button
                                type="is-danger"
                                icon-pack="fa"
                                icon-right="trash"
                                @click="deleteSpecificAnchor(index)"
                            />
                        </div>
                    </div>
                </div>
                <b-message v-if="totalSpecificAnchor > spots" type="is-danger">
                    Attention ! Vous avez saisi plus d'ancre que de RD souhaité. Merci de corriger.
                </b-message>
                <b-button @click="addNewAnchor" :disabled="totalSpecificAnchor === spots">
                    Ajouter une ancre spécifique
                </b-button>
                <b-button class="ml-2" @click="addAnchors" :disabled="totalSpecificAnchor === spots">
                    Ajouter plusieurs ancres spécifiques
                </b-button>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div v-if="urlIsValid && selectedCategory && totalSpecificAnchor <= spots">
                <hr v-if="urlIsValid" />
                <h4 v-if="urlIsValid">Récapitulatif</h4>
                <section>
                    <b-notification
                        class="message-body"
                        v-if="notEnoughRds && selectedCategory.id !== 1"
                        role="alert"
                        type="is-warning"
                        has-icon
                        :closable="false"
                    >
                        <p>
                            Il y a
                            <strong
                                >{{ selectedCategory.spots_count }} RD disponible{{
                                    selectedCategory.spots_count > 1 ? 's' : ''
                                }}</strong
                            >
                            dans la thématique <strong>{{ selectedCategory.translation_name }}</strong>
                            <br />
                            Si vous continuez votre achat,
                            <strong>{{ spots - selectedCategory.spots_count }} RD</strong> seront achetés dans une
                            thématique généraliste.
                        </p>
                    </b-notification>
                </section>
                <table class="table is-fullwidth" v-if="urlIsValid">
                    <thead>
                        <tr>
                            <th class="text-center" width="33%">URL</th>
                            <th class="text-center" width="33%">Nombre de RD</th>
                            <th class="text-center" width="33%">Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-center">{{ url }}</td>
                            <td class="text-center">{{ spots }} x {{ price }} €</td>
                            <td class="text-center">
                                <b-button
                                    v-if="canBuy && hasAvailableSpots"
                                    @click="openBuyRDModal()"
                                    type="is-warning"
                                    icon-pack="fas"
                                    icon-left="cart-plus"
                                >
                                    {{ spots * price }} €
                                </b-button>
                                <b-button
                                    v-else
                                    v-on="!canBuy ? { click: () => redirectTo('/buy-credits') } : {}"
                                    type="is-danger"
                                    icon-pack="fas"
                                    icon-left="cart-plus"
                                >
                                    {{ spots * price }} €
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </transition>

        <BuyRDModal
            ref="buyRDModal"
            :price="price"
            :spots="spots"
            :url="url"
            :category="selectedCategory"
            :specific-anchors="specific_anchors"
        />
        <AddSpecificAnchorsModal @addAnchors="addSpecificAnchors" ref="addSpecificAnchorsModal" />
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex'
    import BuyRDModal from '@/components/Spots/BuyRDModal.vue'
    import AddSpecificAnchorsModal from '../../components/Spots/AddSpecificAnchorsModal.vue'

    export default {
        name: 'BuySpot',
        components: { AddSpecificAnchorsModal, BuyRDModal },
        title: 'Achat de RD',
        data() {
            return {
                availableSpots: null,
                customSpots: false,
                minCustomSpots: 1,
                spots: 1,
                url: this.$route.params.url || '',
                price: 22,
                verifyUrlLoading: false,
                errorUrl: false,
                urlIsValid: false,
                timer: null,

                specific_anchors: [],
                groups: [],
                selectedCategory: null,
                loadingCategories: false
            }
        },
        created() {
            if (this.url) this.initTimer()
            this.getConfigByName({ name: 'spot_price' }).then(res => {
                this.price = res / 100
            })
        },
        computed: {
            ...mapState('auth', {
                credits: state => state.user?.credit_amount,
                benefits: state => state.user?.gains
            }),
            rdTooLow() {
                return this.spots < this.minCustomSpots && this.customSpots
            },
            canBuy() {
                return this.credits >= this.spots * this.price || this.benefits >= this.spots * this.price
            },
            hasAvailableSpots() {
                return this.availableSpots && this.availableSpots >= this.spots
            },
            lowerUrl: {
                get() {
                    return this.url.toLowerCase()
                },
                set(newValue) {
                    this.url = newValue
                }
            },
            totalSpecificAnchor() {
                return parseInt(this.specific_anchors.reduce((acc, curr) => acc + curr.rd_number, 0))
            },
            notEnoughRds() {
                if (this.selectedCategory) {
                    return this.spots > this.selectedCategory.spots_count
                }

                return false
            }
        },
        methods: {
            ...mapActions('config', {
                getConfigByName: 'getConfigByName'
            }),
            ...mapActions('spots', {
                countAvailableSpots: 'countAvailableSpots',
                getAvailableCategories: 'getAvailableCategories'
            }),
            addAnchors() {
                this.$refs.addSpecificAnchorsModal.show()
            },
            addSpecificAnchors(params) {
                const newAnchors = params.anchors.map(anchor => ({
                    anchor: anchor,
                    rd_number: parseInt(params.rd_number)
                }))
                this.specific_anchors.push(...newAnchors)
            },
            setSpots(spots) {
                this.customSpots = false
                this.spots = spots
            },
            openBuyRDModal() {
                this.$refs.buyRDModal.openModal()
            },
            initTimer() {
                if (this.timer) {
                    clearTimeout(this.timer)
                }

                this.timer = setTimeout(this.validateUrl, 600)
            },
            validateUrl() {
                this.errorUrl = false
                this.urlIsValid = false
                this.availableSpots = null
                this.selectedCategory = null

                if (this.url.length < 11) {
                    this.errorUrl = "L'url est invalide"
                    return
                }

                if (!this.isUrlValid(this.url)) {
                    this.errorUrl = "L'url est invalide"
                    return
                }

                this.verifyUrlLoading = true
                this.checkUrl()
            },
            isUrlValid(url) {
                let givenURL
                try {
                    givenURL = new URL(url)
                } catch (error) {
                    return false
                }
                return givenURL.protocol === 'http:' || givenURL.protocol === 'https:'
            },
            checkUrl() {
                return this.axios
                    .post('/client/check-url', {
                        url: this.url
                    })
                    .then(response => {
                        this.errorUrl = false
                        this.urlIsValid = response.data.result

                        this.countAvailableSpots({ url: this.url }).then(res => {
                            this.availableSpots = res
                        })

                        if (this.urlIsValid) {
                            this.loadingCategories = true
                            this.getAvailableCategories({ exclude_url: this.url }).then(res => {
                                this.groups = res
                                this.loadingCategories = false
                            })
                        }
                    })
                    .catch(error => {
                        this.errorUrl = error.response.data.message
                    })
                    .finally(() => {
                        this.verifyUrlLoading = false
                    })
            },
            redirectTo(url) {
                this.$router.push(url)
            },
            addNewAnchor() {
                if (this.totalSpecificAnchor === this.spots) return
                this.specific_anchors.push({
                    anchor: '',
                    rd_number: 1
                })
            },
            deleteSpecificAnchor(index) {
                this.specific_anchors.splice(index, 1)
            }
        }
    }
</script>

<style scoped></style>